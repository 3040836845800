<template>
  <div class="center">
    <img src="../assets/icon/error.svg" alt="">
    <!--    <p>支付失败，请重新扫码进入！</p>-->
    <p>错误: {{ msg }}</p>
    <div class="bnt-pay">
      <div class="bnt-pay-text" @click="close">
        关闭
      </div>
    </div>
  </div>
</template>

<script>
import wayCodeUtils from "@/utils/wayCode";

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  data() {
    return {
      msg: "请重新扫码进入！",
    }
  },
  components: {

  },
  mounted() {
    this.msg = this.$route.params.errInfo || '请重新扫码进入！'
  },
  methods: {
    close() {
      if (wayCodeUtils.getPayWay().name == "wx") {
        if (wayCodeUtils.getPayWay().mini) {
          // eslint-disable-next-line no-undef
          wx.miniProgram.navigateBack()
        } else {
          window.WeixinJSBridge.call("closeWindow");
        }
      } else if (wayCodeUtils.getPayWay().name == "zfb") {
        if (wayCodeUtils.getPayWay().mini) {
          // eslint-disable-next-line no-undef
          my.navigateBack()
        } else {
          window.AlipayJSBridge.call("closeWebview");
        }
      }
    },
  }
}
</script>
<style lang="css" scoped>
.center {
  margin-top: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

p {
  font-size: 36px;
  margin-top: 50px;
}

.bnt-pay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100px;
  width: 100vw;
  z-index: 1;
}

.bnt-pay-text {
  width: 300px;
  color: #fff;
  height: 100px;
  border-radius: 16px;
  background-color: #FF4B33;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
}
</style>
